import React, { useState, useEffect, useRef } from 'react';
import AvatarBuilder from '../avatar/components/AvatarProperties';
import { AvatarProperties } from '../avatar/components/AvatarProperties';
import HairStyle, { hairColors } from '../avatar/components/top/HairStyle';
import Clothes, { clotheColors } from '../avatar/components/clothes/Clothes';
import FacialHair, { facialHairColors } from '../avatar/components/top/FacialHair';
import Eyes from '../avatar/components/face/Eyes';
import Eyebrow from '../avatar/components/face/Eyebrow';
import Mouth from '../avatar/components/face/Mouth';
import Skin from '../avatar/components/Skin';
import Accessories from '../avatar/components/top/Accessories';
import './AvatarEditor.css'

import { saveAvatar, loadAvatar } from '../../ApiCalls/Profile/api_v1_profile';

const AvatarEditor = () => {
    const [activeTab, setActiveTab] = useState('FACIAL HAIR');
    const [avatarOptions, setAvatarOptions] = useState([]);
    const [userOptions, setUserOptions] = useState(AvatarProperties.defaultProperties);
    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setSaveError] = useState(null);
    const tabsRef = useRef(null);

    const tabs = [
        'FACIAL HAIR',
        'FACIAL HAIR COLOR',
        'HAIRSTYLE',
        'HAIR COLOR',
        'OUTFIT',
        'OUTFIT COLOR',
        'EYES',
        'EYEBROWS',
        'MOUTH',
        'SKIN',
        'ACCESSORIES'
    ];


    useEffect(() => {
        if (tabsRef.current) {
            const activeTab = tabsRef.current.querySelector('.avatar-tab-active');
            if (activeTab) {
                activeTab.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'nearest' });
            }
        }
    }, [activeTab]);


    useEffect(() => {
        updateAvatarOptions(activeTab);
    }, [activeTab]);

    useEffect(() => {
        const loadSavedOptions = async () => {
            try {
                const response = await loadAvatar();
                if (response && response.data && response.data.avatar) {
                    const avatarData = response.data.avatar;
                    const relevantOptions = {
                        style: avatarData.style || "Transparent",
                        topType: avatarData.topType || "Flat",
                        accessoriesType: avatarData.accessoriesType || "Nothing",
                        hairColor: avatarData.hairColor || "Black",
                        facialHairType: avatarData.facialHairType || "Nothing",
                        facialHairColor: avatarData.facialHairColor || "BlondeGolden",
                        clotheType: avatarData.clotheType || "Crew Neck Tee",
                        clotheColor: avatarData.clotheColor || "Blue01",
                        eyeType: avatarData.eyeType || "Default",
                        eyebrowType: avatarData.eyebrowType || "Default",
                        mouthType: avatarData.mouthType || "Default",
                        skinColor: avatarData.skinColor || "White"
                    };
                    setUserOptions(relevantOptions);
                }
            } catch (error) {
                console.error('Error loading saved avatar options:', error);
            }
        };
        loadSavedOptions();
    }, []);

    const handleSaveAvatar = async () => {
        setIsSaving(true);
        setSaveError(null);
        try {
            localStorage.setItem('avatarOptions', JSON.stringify(userOptions));
            localStorage.setItem('avatarChanged', 'true');

            const response = await saveAvatar(userOptions);
            if (response.data) {
            } else {
                throw new Error('No data received from the server');
            }
        } catch (error) {
            console.error('Error saving avatar:', error);
            setSaveError('Failed to save avatar. Please try again.');
        } finally {
            setIsSaving(false);
        }
    };

    const updateAvatarOptions = (tab) => {
        let options = [];
        switch (tab) {
            case 'OUTFIT':
                options = AvatarProperties.clotheTypeOptions;
                break;
            case 'HAIRSTYLE':
                options = AvatarProperties.topTypeOptions;
                break;
            case 'FACIAL HAIR':
                options = AvatarProperties.facialHairTypeOptions;
                break;
            case 'EYES':
                options = AvatarProperties.eyeTypeOptions;
                break;
            case 'EYEBROWS':
                options = AvatarProperties.eyebrowTypeOptions;
                break;
            case 'MOUTH':
                options = AvatarProperties.mouthTypeOptions;
                break;
            case 'SKIN':
                options = AvatarProperties.skinColorOptions;
                break;
            case 'ACCESSORIES':
                options = AvatarProperties.accessoriesTypeOptions;
                break;
            case 'HAIR COLOR':
                options = AvatarProperties.hairColorOptions;
                break;
            case 'FACIAL HAIR COLOR':
                options = AvatarProperties.facialHairColorOptions;
                break;
            case 'OUTFIT COLOR':
                options = AvatarProperties.clotheColorOptions;
                break;
            default:
                options = [];
        }
        setAvatarOptions(options);
    };

    const generateSvgForOption = (option) => {
        if (option === "Bald" || option === "Nothing") {
            return AvatarProperties.emptySVGIcon;
        }

        let svgContent = "";
        switch (activeTab) {
            case "HAIRSTYLE":
                svgContent = HairStyle({ hairType: option, hColor: userOptions.hairColor });
                return wrapSvg(svgContent, "10 0 250 250");
            case "OUTFIT":
                svgContent = Clothes({ clotheType: option, clColor: userOptions.clotheColor });
                return wrapSvg(svgContent, "30 100 200 250");
            case "FACIAL HAIR":
                svgContent = FacialHair({ facialHairType: option, fhColor: userOptions.facialHairColor });
                return wrapSvg(svgContent, "0 -40 112 180");
            case "EYES":
                svgContent = Eyes({ eyeType: option });
                return wrapSvg(svgContent, "-3 -30 120 120");
            case "EYEBROWS":
                svgContent = Eyebrow({ eyebrowType: option });
                return wrapSvg(svgContent, "-3 -50 120 120");
            case "MOUTH":
                svgContent = Mouth({ mouthType: option });
                return wrapSvg(svgContent, "0 10 120 120");
            case "SKIN":
                svgContent = Skin({ skinType: option });
                return `<svg width="264px" height="280px" viewBox="0 0 264 280" xmlns="http://www.w3.org/2000/svg">
                    ${AvatarProperties.skinBase}${svgContent}
                    <path d="M156,79 L156,102 C156,132.927946 130.927946,158 100,158 C69.072054,158 44,132.927946 44,102 L44,79 L44,94 C44,124.927946 69.072054,150 100,150 C130.927946,150 156,124.927946 156,94 L156,79 Z" id="Neck-Shadow" opacity="0.100000001" fill="#000000" mask="url(#mask-6)"></path>
                    </g></g></g></g></g></svg>`;
            case "ACCESSORIES":
                svgContent = Accessories({ accessoryType: option });
                return wrapSvg(svgContent, "-3 -50 120 170");
            case "HAIR COLOR":
                return generateColorSwatch(hairColors[option]);
            case "FACIAL HAIR COLOR":
                return generateColorSwatch(facialHairColors[option]);
            case "OUTFIT COLOR":
                return generateColorSwatch(clotheColors[option]);
            default:
                return "<svg></svg>";
        }
    };

    const generateColorSwatch = (colorHex) => {
        return `<svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
            <circle cx="30" cy="30" r="25" fill="${colorHex}" stroke="#ffffff" stroke-width="2"/>
        </svg>`;
    };

    const wrapSvg = (content, viewBox) => {
        return `<svg width="140" height="140" viewBox="${viewBox}" xmlns="http://www.w3.org/2000/svg">
            ${content}
        </svg>`;
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const handleOptionClick = (option) => {
        setUserOptions(prev => {
            const key = getKeyForTab(activeTab);
            return { ...prev, [key]: option };
        });
    };

    const getKeyForTab = (tab) => {
        switch (tab) {
            case 'OUTFIT': return 'clotheType';
            case 'HAIRSTYLE': return 'topType';
            case 'FACIAL HAIR': return 'facialHairType';
            case 'EYES': return 'eyeType';
            case 'EYEBROWS': return 'eyebrowType';
            case 'MOUTH': return 'mouthType';
            case 'SKIN': return 'skinColor';
            case 'ACCESSORIES': return 'accessoriesType';
            case 'HAIR COLOR': return 'hairColor';
            case 'FACIAL HAIR COLOR': return 'facialHairColor';
            case 'OUTFIT COLOR': return 'clotheColor';
            default: return '';
        }
    };

    const createRipple = (event) => {
        const button = event.currentTarget;
        const circle = document.createElement("span");
        const diameter = Math.max(button.clientWidth, button.clientHeight);
        const radius = diameter / 2;

        circle.style.width = circle.style.height = `${diameter}px`;
        circle.style.left = `${event.clientX - button.offsetLeft - radius}px`;
        circle.style.top = `${event.clientY - button.offsetTop - radius}px`;
        circle.classList.add("ripple");

        const ripple = button.getElementsByClassName("ripple")[0];
        if (ripple) {
            ripple.remove();
        }

        button.appendChild(circle);
    };

    return (
        <div className="avatar-editor-layout">
            <div className="avatar-preview-area">
                <div className="avatar-preview-content">
                    <AvatarBuilder properties={userOptions} />
                </div>
                <button
                    className="avatar-save-button"
                    onClick={handleSaveAvatar}
                    disabled={isSaving}
                >
                    {isSaving ? 'Saving...' : 'Save'}
                </button>
                {saveError && <div className="save-error">{saveError}</div>}
            </div>
            <h2 className="avatar-customize-title">Customize according to your choice</h2>
            <div className="avatar-tabs-container" ref={tabsRef}>
                {tabs.map(tab => (
                    <button
                        key={tab}
                        className={`avatar-tab ${activeTab === tab ? 'avatar-tab-active' : ''}`}
                        onClick={() => handleTabClick(tab)}
                    >
                        {tab}
                    </button>
                ))}
            </div>
            <div className="avatar-content-area">
                <div className="avatar-options-grid">
                    {avatarOptions.map(option => (
                        <button
                            key={option}
                            className={`avatar-option ${activeTab.includes('COLOR') ? 'color-option' : ''}`}
                            onClick={(e) => {
                                createRipple(e);
                                handleOptionClick(option);
                            }}
                            dangerouslySetInnerHTML={{ __html: generateSvgForOption(option) }}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default function AvatarEditorStylized() {
    return (
        <>
            <AvatarEditor />
        </>
    );
}