import React, { useLayoutEffect, useEffect, useState, useMemo, useRef } from 'react';
import { questionRetrive, questionSubmit } from "../../ApiCalls/Home/api_v1_home";
import { useNavigate, useLocation } from "react-router-dom";
import styles from '../../styles/question_time/QuestionTime.module.css';
import triviaTrack from '../../audio/trivia.aac';
import correctTrack from '../../audio/correct.wav';
import incorrectTrack from '../../audio/incorrect.wav';
// import ding_token from '../../images/v1/home/ding_token.png';
// import timerSmoke from "../../animations/triv.webm";
// import star_token from '../../images/v1/home/token.png';
import leftArrow from "../../images/v1/icons/left_arrow.png";
import bg1 from "../../images/v1/trivia/ding_bg_1.avif";
import bg2 from "../../images/v1/trivia/ding_bg_2.avif";
import bg3 from "../../images/v1/trivia/ding_bg_3.avif";
import bg4 from "../../images/v1/trivia/ding_bg_4.avif";
import bg5 from "../../images/v1/trivia/ding_bg_5.avif";

function QuestionTime() {
    const containerRef = useRef(null);
    let videoId = (new URLSearchParams(window.location.search)).get("id")
    const location = useLocation();
    // const [questionData, setQuestionData] = useState([]);
    const [questionUUID, setQuestionUUID] = useState();
    const questionTitle = useRef(null);
    const questionChoice1 = useRef(null);
    const questionChoice2 = useRef(null);
    const questionChoice3 = useRef(null);
    const questionChoice4 = useRef(null);
    const correctPromt = useRef(null);
    const [choices, setChoices] = useState(4);
    const [attempts, setAttempts] = useState(1);
    let navigate = useNavigate();
    const [seconds, setSeconds] = useState(10);
    const interval = useRef(null)
    const triviaNumber = useRef(2);
    const triviaTrackPlaying = useRef(false);
    var track = useRef(new Audio(triviaTrack));
    const correct = useMemo(() => new Audio(correctTrack), []);
    // useRef(new Audio(correctTrack));
    const incorrect = useMemo(() => new Audio(incorrectTrack), []);
    // useRef(new Audio(incorrectTrack));
    const [showLinear, setShowLinear] = useState(0);
    const showInterval = useRef(null);
    const intervalCount = useRef(0);
    const intervalState = useRef(false);
    const totalQuestions = useRef(0);
    const [isDisabled, setIsDisabled] = useState(false);
    const bgImages = useRef({ 'img1': bg1, "img2": bg2, "img3": bg3, "img4": bg4, "img5": bg5 });
    const [bgImgSrc, setBgImgSrc] = useState('');
    const getRandomImage = () => {
        const keys = Object.keys(bgImages.current);
        const randomKey = keys[Math.floor(Math.random() * keys.length)];
        return bgImages.current[randomKey];
    };
    useLayoutEffect(() => {
        // document.body.style.backgroundColor = "#191919";
        document.querySelector("meta[name='theme-color']").setAttribute("content", "#191919");
        document.title = "Trivia | Ding";
        setBgImgSrc(getRandomImage());

        if (containerRef.current) {
            containerRef.current.style.overflow = "hidden";
        }

        return () => {
            if (containerRef.current) {
                containerRef.current.style.overflow = "";
            }
        };
    }, []);

    useEffect(() => {

        touchstart();

        return () => {
            // second
            pauseTrack();
            // track.current.pause();
            // console.log("cleaned up");
        }
    }, [])
    const touchstart = () => {

        if (triviaTrackPlaying.current == false) {
            triviaTrackPlaying.current = true;
            // track.loop=true;
            playTrack();
            // track.current.play();

        }
    };
    const playTrack = () => {
        track.current.pause();
        track.current.currentTime = 0;
        track.current.volume = 0.07;
        track.current.loop = true;
        track.current.play();
    }
    const pauseTrack = () => {
        triviaTrackPlaying.current = false;
        track.current.pause();
    }
    const playCorrect = () => {
        correct.volume = 0.03;
        correct.play();
    }
    const playIncorrect = () => {
        incorrect.volume = 0.05;
        incorrect.play();
    }
    const resetButtonStyles = () => {
        [questionChoice1, questionChoice2, questionChoice3, questionChoice4].forEach(ref => {
            if (ref.current) {
                ref.current.style.backgroundColor = "rgba(0, 0, 0, 0.2)";
                ref.current.style.color = "white";
                ref.current.style.border = "none";
            }
        });
    };
    useEffect(() => {
        totalQuestions.current++;
        let attemptNumber;
        if (location.state != null) {
            attemptNumber = location.state.attemptNumber;
            totalQuestions.current = 0;
            setAttempts(attemptNumber);
            location.state = null;
        } else {
            attemptNumber = attempts;
        }
        // console.log(attemptNumber, totalQuestions.current);
        let dataQuestion = {
            "videoId": videoId,
            "questionId": attemptNumber,
        }
        questionRetrive(dataQuestion).then(response => {
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            if (response.data.message === "Ko" && response.data.data.trivia === "no quiz available") {
                //    return navigate('/home',{state:{id:videoId}})   
                return navigate('/rewards', { state: { id: videoId, questionUUID: questionUUID } })
            }
            else {
                resetButtonStyles();
                intervalCount.current = 0;
                setShowLinear(0);
                let data = response.data.data;
                // console.log(data.title);
                // let title = data.title;
                triviaNumber.current = data.trivia;
                let customArray = [data.quiz.c1, data.quiz.c2, data.quiz.c3, data.quiz.c4];
                let totalChoices = 0;
                customArray.forEach(element => {
                    if (element) {
                        totalChoices++;
                    }
                });
                if (totalChoices == 4) {
                    setChoices(4)
                }
                if (totalChoices == 3) {
                    setChoices(3)
                }
                if (totalChoices == 2) {
                    setChoices(2)
                }
                if (totalChoices == 2) {
                    let newCustomArray = [];
                    customArray.forEach(element => {
                        if (element) {
                            newCustomArray.push(element);
                        }
                    });


                    let shuffeledArray = newCustomArray.sort((a, b) => 0.3 - Math.random());
                    questionTitle.current.innerHTML = data.quiz.title

                    questionChoice1.current.value = shuffeledArray[0];
                    questionChoice1.current.innerHTML = shuffeledArray[0];

                    questionChoice2.current.value = shuffeledArray[1];
                    questionChoice2.current.innerHTML = shuffeledArray[1];
                    setQuestionUUID(data.questionUUID);


                }
                if (totalChoices == 3) {
                    let newCustomArray = [];
                    customArray.forEach(element => {
                        if (element) {
                            newCustomArray.push(element);
                        }
                    });
                    let shuffeledArray = newCustomArray.sort((a, b) => 0.3 - Math.random());
                    questionTitle.current.innerHTML = data.quiz.title

                    questionChoice1.current.value = shuffeledArray[0];
                    questionChoice1.current.innerHTML = shuffeledArray[0];

                    questionChoice2.current.value = shuffeledArray[1];
                    questionChoice2.current.innerHTML = shuffeledArray[1];

                    questionChoice3.current.value = shuffeledArray[2];
                    questionChoice3.current.innerHTML = shuffeledArray[2];
                    setQuestionUUID(data.questionUUID);

                }
                if (totalChoices == 4) {
                    let shuffeledArray = customArray.sort((a, b) => 0.3 - Math.random());

                    let newCustomArray2 = []; //this array will hold all of above and none of above.
                    let newCustomArray3 = []; //this array will not hold all of above and none of above.
                    let finalArray = []; //final array will hold elements by concatenation of newcustomarray2 nd 3;
                    setQuestionUUID(data.questionUUID);
                    questionTitle.current.innerHTML = data.quiz.title;
                    shuffeledArray.forEach(element => {
                        if ((element !== "All of the above") && (element !== "All of above") && (element !== "None of the above")) {
                            newCustomArray2.push(element);
                            // console.log("true",element);
                        }
                        if (element && (element === "All of the above") || (element === "All of above") || (element === "None of the above")) {
                            newCustomArray3.push(element);
                            // console.log('false',element);
                        }
                    });
                    newCustomArray2.sort((a, b) => 0.3 - Math.random());
                    finalArray = newCustomArray2.concat(newCustomArray3);
                    questionChoice1.current.value = finalArray[0];
                    questionChoice1.current.innerHTML = finalArray[0];

                    questionChoice2.current.value = finalArray[1];
                    questionChoice2.current.innerHTML = finalArray[1];

                    questionChoice3.current.value = finalArray[2];
                    questionChoice3.current.innerHTML = finalArray[2];

                    questionChoice4.current.value = finalArray[3];
                    questionChoice4.current.innerHTML = finalArray[3];
                }

                if (intervalState.current == false) {
                    intervalState.current = true
                }

                // questionUUID.current.value = data.questionUUID;

                // console.log(questionChoice1,questionChoice2,questionChoice3,questionChoice4)


            }
        })
            .catch(error => {
                // console.log(error);
                // console.log(error.response.status,error.response.data.message);
            })
    }, [attempts]);



    const handleSubmit = (e) => {
        triviaTrackPlaying.current = true;
        track.current.pause();
        track.current.volume = 0.07;
        track.current.loop = true;
        track.current.play();
        clearInterval(interval.current);
        setIsDisabled(true);
        e.preventDefault();
        const button = e.target;
        // Save the original styles
        const originalBorderStyle = button.style.border;
        const originalColorStyle = button.style.color;
        // Modify the styles
        button.style.border = '2px solid gray';
        button.style.color = 'gray';

        let redClr = "#bd0d0f";
        let greenClr = "#d5f04b";
        let origionalClrBtn = "rgba(0, 0, 0, 0.2)";
        let origionalClrBg = "rgba(0, 0, 0, 0.2)";
        let color = 0;
        let colorInterval;
        let submitedAnswer = e.target.value;
        // let prompt = document.getElementById('corectPrompt');
        let answerData = { "videoId": videoId, "questionUUID": questionUUID, "questionNumber": attempts, "answer": submitedAnswer };
        questionSubmit(answerData).then(response => {
            button.style.border = originalBorderStyle;
            button.style.color = originalColorStyle;
            const accessToken = response.headers['access_token'];
            if (accessToken != null) {
                localStorage.setItem('token', accessToken);
            }
            if (response.data.message === "Ko") {
                return navigate('/home', { state: { id: videoId } })
            }
            if (response.data.data.status == "false") {
                playIncorrect();
                colorInterval = setInterval(myTimer, 100);
                function myTimer() {
                    e.target.style.backgroundColor = redClr; //changed color
                    correctPromt.current.innerHTML = response.data.data.hint;
                    correctBtnHighlight(response.data.data.hint, 1);
                    color++;
                    // console.log(color);
                    if (color & 1) {
                        // console.log("odd");
                        // document.body.style.backgroundColor = redClr;
                    }
                    else {
                        // console.log("even");
                        // document.body.style.backgroundColor = origionalClrBg;
                    }

                    // }
                    //restore origional color
                    if (color > 5) {
                        clearInterval(colorInterval);
                        // document.body.style.backgroundColor = origionalClrBg;
                        // correctPromt.current.innerHTML = "";
                        setTimeout(
                            () => removePromptText(e, false),
                            3000
                        );
                        setTimeout(
                            () => correctBtnHighlight(response.data.data.hint, 0),
                            3000
                        );
                    }
                }
            }
            else if (response.data.data.status == "true") {
                playCorrect();
                colorInterval = setInterval(myTimer, 100);
                function myTimer() {
                    e.target.style.backgroundColor = greenClr; //changed color
                    e.target.style.color = origionalClrBtn;
                    correctPromt.current.innerHTML = response.data.data.hint;
                    color++;
                    if (color & 1) {
                        // document.body.style.backgroundColor = greenClr;
                    }
                    else {
                        // document.body.style.backgroundColor = origionalClrBg;
                    }
                    //restore origional color
                    if (color > 5) {
                        clearInterval(colorInterval);
                        // document.body.style.backgroundColor = origionalClrBg;
                        setTimeout(
                            () => removePromptText(e, true),
                            3000
                        );
                        // correctPromt.current.innerHTML = "";
                    }
                }
            }
            setTimeout(
                () => {
                    if (triviaNumber.current > 0 && totalQuestions.current !== 3) {
                        setAttempts(attempts + 1);
                        resetButtonStyles(); // Reset styles before loading next question
                    } else if (triviaNumber.current > 0 && totalQuestions.current === 3) {
                        gotoNextLevel();
                    } else {
                        navigate('/rewards', { state: { id: videoId, questionUUID: questionUUID } });
                    }
                },
                3500
            );

        })
            .catch(error => {
                // console.log(error);
                // console.log(error.response.status,error.response.data.message);
                if (error.response.status === 404 && error.response.data.message === "Ko") {
                    return navigate('/reward', { state: { id: videoId } });
                }
            })

    }
    function gotoNextLevel() {
        intervalState.current = false;
        navigate('/rewards', { state: { id: videoId, questionUUID: questionUUID, backToVideos: true, returnAttempt: attempts } })
    }

    function removePromptText(e, trueFalse) {
        resetButtonStyles();
        correctPromt.current.innerHTML = " ";
        setIsDisabled(false);
    }

    function correctBtnHighlight(hintText, showHide) {
        if (showHide == 1) {
            let greenClr = "#d5f04b";
            let origionalClrBtn = "white";
            if (questionChoice1.current.innerHTML == hintText) {
                questionChoice1.current.style.backgroundColor = greenClr; //changed color
                questionChoice1.current.style.color = "black";
            }
            if (questionChoice2.current.innerHTML == hintText) {
                questionChoice2.current.style.backgroundColor = greenClr; //changed color
                questionChoice2.current.style.color = "black";
            }
            if (questionChoice3.current.innerHTML == hintText) {
                questionChoice3.current.style.backgroundColor = greenClr; //changed color
                questionChoice3.current.style.color = "black";
            }
            if (questionChoice4.current.innerHTML == hintText) {
                questionChoice4.current.style.backgroundColor = greenClr; //changed color
                questionChoice4.current.style.color = "black";
            }
        }
        else {
            let origionalClrBtn = "rgba(0, 0, 0, 0.2)";
            if (questionChoice1.current.innerHTML == hintText) {
                questionChoice1.current.style.backgroundColor = origionalClrBtn; //changed color
                questionChoice1.current.style.color = "white";
            }
            if (questionChoice2.current.innerHTML == hintText) {
                questionChoice2.current.style.backgroundColor = origionalClrBtn; //changed color
                questionChoice2.current.style.color = "white";
            }
            if (questionChoice3.current.innerHTML == hintText) {
                questionChoice3.current.style.backgroundColor = origionalClrBtn; //changed color
                questionChoice3.current.style.color = "white";
            }
            if (questionChoice4.current.innerHTML == hintText) {
                questionChoice4.current.style.backgroundColor = origionalClrBtn; //changed color
                questionChoice4.current.style.color = "white";
            }

        }

    }

    function navigateHome() {
        if (isDisabled) {
            return;
        }
        return navigate("/home/landing");
    }
    function skipQuestion() {
        (triviaNumber.current > 0 && totalQuestions.current != 3)
            ?
            setAttempts(attempts + 1)
            :
            (triviaNumber.current > 0 && totalQuestions.current === 3)
                ?
                // console.log("totalQuestion = ", totalQuestions, totalQuestions.current)
                gotoNextLevel()

                :
                navigate('/rewards', { state: { id: videoId, questionUUID: questionUUID } })
    }
    return (
        <main ref={containerRef} style={{ height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <img src={bgImgSrc} alt="." style={{ position: "fixed", height: "100%", width: "100%", zIndex: "-3" }} />

            <div className={`${styles.skipQuestionBtnContainer}`}>
                <button type="button" className={styles.skipQuestionButton} onClick={skipQuestion} disabled={isDisabled}>skip</button>
            </div>

            <div className={styles.QuestionTimeContainer} style={{ flex: 1, overflowY: 'auto', padding: '20px' }}>
                <br />
                <div className={styles.questionStatement}>
                    <p className={styles.questionTitle} ref={questionTitle}></p>
                </div>
                <div className="d-flex flex-column mb-c4 mt-3rem">
                    <div className={styles.mb_1}>
                        <button type="button" name="answer" disabled={isDisabled} onClick={(e) => { handleSubmit(e) }} className={`${styles.mb_0} ${styles.answerBtn}`} value={questionChoice1} ref={questionChoice1}></button>
                    </div>
                    <div className={styles.mb_1}>
                        <button type="button" name="answer" disabled={isDisabled} onClick={(e) => { handleSubmit(e) }} className={`${styles.mb_0} ${styles.answerBtn}`} value={questionChoice2} ref={questionChoice2}></button>
                    </div>
                    <div className={(choices == 2 ? styles.hidden : styles.mb_1)}>
                        <button type="button" name="answer" disabled={isDisabled} onClick={(e) => { handleSubmit(e) }} className={`${styles.mb_0} ${styles.answerBtn}`} value={questionChoice3} ref={questionChoice3}></button>
                    </div>
                    <div className={(choices == 2 || choices == 3 ? styles.hidden : styles.mb_1)}>
                        <button type="button" name="answer" disabled={isDisabled} onClick={(e) => { handleSubmit(e) }} className={`${styles.mb_0} ${styles.answerBtn}`} value={questionChoice4} ref={questionChoice4}></button>
                    </div>
                </div>
                <div className={`${styles.corectAnswerPrompt} ${styles.displayNone}`}>
                    <p id="corectPrompt" ref={correctPromt}></p>
                </div>
            </div>
            <br />  <br /> <br />
        </main>
    );
}

export default React.memo(QuestionTime);